import Layout from "../layout"
import Lenis from '@studio-freight/lenis'
import Page from "../components/page"

class App extends Layout {
    constructor() {
        super()
        this.page = new Page()
        this.setLenis()
    }

    setLenis() {
        this.lenis = new Lenis({
            duration: .1,
            lerp: this.lerp,
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            smoothWheel: true,
            smoothTouch: true
        })
        //
        window.RAF.on("animate", (delta) => this.render(delta))
    }
    
    render() {
        const time = new Date().getTime() * .1
        this.lenis.raf(time)
        //STATS.update()
    }

}

const _app = new App()